<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >部门：
            <DeptCascader
              :placeholder="'请选择部门'"
              :deptList="deptList"
              v-model="parameter.departmentId"
            />
          </span>
          <span
            >年度：
            <el-select
              class="ipt_width"
              v-model="parameter.year"
              clearable
              @change="dataUpdate"
              placeholder="请选择年度"
              style="width: 150px; margin-right: 15px"
            >
              <el-option
                v-for="item in YearList"
                :label="item.dictName"
                :value="item.dictVal"
                :key="item.dictVal"
              ></el-option>
            </el-select>
          </span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" v-loading="loading">
            <el-table-column type="index" align="center" label="	序号" width="50"> </el-table-column>
            <el-table-column align="center" prop="departmentName" label="部门名称">
            </el-table-column>
            <el-table-column align="center" prop="year" sortable min-width="120" label="年度">
              <template slot-scope="scope">
                {{ scope.row.year | dict(dictData.projectYear) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="totalPerformance" label="部门管理总绩效(元)">
              <template slot-scope="scope">
                {{ scope.row.totalPerformance | thousands }}
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="70">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  @click.stop="edit(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      max-height="400"
      width="70%"
      title="部门绩效详情"
      :visible.sync="dialogTableVisible"
      :before-close="handleClose"
    >
      <el-table
        ref="multipleTable"
        border
        max-height="400"
        :data="depTableData"
        v-loading="loading"
      >
        <el-table-column type="index" align="center" label="	序号" width="60"> </el-table-column>
        <el-table-column align="center" prop="year" label="年度">
          <template slot-scope="scope">
            {{ scope.row.year | dict(dictData.projectYear) }}
          </template>
        </el-table-column>

        <el-table-column
          width="350"
          align="center"
          prop="title"
          label="项目名称"
          :show-overflow-tooltip="false"
        ></el-table-column>
        <el-table-column align="center" prop="departmentName" label="	部门名称"> </el-table-column>
        <el-table-column align="center" prop="type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.type | dict(typeList) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="typeDetail" label="类型详情">
          <template slot-scope="scope">
            {{ scope.row.typeDetail | dict(typeDetailList) }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="170" prop="produceTime" label="产生时间">
          <template slot-scope="scope">
            {{ scope.row.produceTime | dateFormat }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="departmentPerformanceDetail" label="部门管理绩效(元)">
          <template slot-scope="scope">
            {{ scope.row.departmentPerformanceDetail | thousands }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 8px"
        @size-change="handleSizeChangeFn"
        @current-change="pageChangeHandlerFn"
        :current-page="parameterDialog.pageNow"
        :page-size="parameterDialog.pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="->,total, prev, pager, next, slot, jumper"
        :total="parameterDialog.total"
      >
        <span class="el-pagination__jump e_a_pagination">
          <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
          <span style="padding-top: 1px">条/页</span>
        </span>
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      parameterDialog: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      dictData: {
        // projectType: [],
        projectYear: [],
      },
      tableData: [],
      depTableData: [],
      deptList: [],
      loading: false,
      // projectList: [],
      YearList: [],
      dialogTableVisible: false,
      typeList: [],
      typeDetailList: [],
    }
  },
  watch: {},
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },

    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.parameterDialog.pageSize = this.userInfo.pageSizeLog
    this.getData()
    // this.getProject();
    this.$api.dict
      .listSysDictData('JX_COST_TYPE', true)
      .then(res => {
        this.typeList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('JX_COST_TYPE_DETAIL', true)
      .then(res => {
        this.typeDetailList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    // this.$api.dict
    //   .listSysDictData("PROJECT_TYPE", true)
    //   .then((res) => {
    //     this.dictData.projectType = res.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    this.$api.dict
      .listSysDictData('PROJECT_YEAR', true)
      .then(res => {
        this.dictData.projectYear = res.data
        this.YearList = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.sysDept
      .getTreeDept()
      .then(res => {
        this.deptList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    handleReset() {
      this.parameter.departmentId = ''
      this.parameter.year = ''
      this.getData() /** 加载 "付款申请审核" 数据 **/
    },
    handleClose(done) {
      this.depTableData = []
      done()
    },
    edit(row) {
      this.parameterDialog.departmentId = row.departmentId
      this.parameterDialog.year = row.year
      this.$api.performanceManagement
        .departmentPerformanceDetailController(this.parameterDialog)
        .then(res => {
          this.loading = false
          this.depTableData = res.data.records
          this.parameterDialog.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
      this.dialogTableVisible = true
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    pageChangeHandlerFn(val) {
      this.parameterDialog.pageNow = val
      this.edit()
    },
    handleSizeChangeFn(val) {
      this.parameterDialog.pageSize = val
      this.parameterDialog.pageNow = 1
      this.edit()
    },
    getData() {
      this.loading = true
      this.$api.performanceManagement
        .departmentPerformanceListTotal(this.parameter)
        .then(res => {
          this.loading = false

          this.tableData = res.data.records

          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
